import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageGrid from "../components/image-grid"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const Wrapper = styled.div`
  margin: auto;
  img {
    width: 600px;
  }
  p {
    font-size: 0.9em;
    font-weight: 200;
  }
`

const FashionWeek = () => {
  const imageQuery = useStaticQuery(graphql`
    {
      bigImages: allFile(
        filter: { relativeDirectory: { eq: "fashion-week" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      gridImages: allFile(
        filter: { relativeDirectory: { eq: "fashion-week/grid" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const bigImages = imageQuery.bigImages.edges
  const gridImages = imageQuery.gridImages.edges

  return (
    <Layout>
      <Wrapper>
        <SEO title="Fashion Week 2019" />
        <p>London – September 14th - 2019</p>

        <p>
          Luka France debuted a sustainable 8 outfit collection created from
          upcycled football kits. Travelling up and down the country visiting
          local & national teams, Luka has put together a contemporary
          collection formed of donated sportswear garments. With a vision of a
          sustainable future in fashion his idea was to blend the solidarity of
          football supporters and the creativity of recycling, to create
          something brand new. In the uncertainty of Britain’s future this
          collection hopes to build a bridge amongst a divided nation.{" "}
        </p>

        <p>
          Featuring a multitude of nationwide sportswear, the collection aims to
          cross boundaries while simultaneously building them. With many teams
          on board with Lukas idea including Blackburn Rovers and Ashton United
          sharing his vision. Luka France aims to lead a breed of new designers
          who are more focused on sustainable creativity, to create a cleaner
          and better world. With his journey just beginning but a large vision
          ahead this London Fashion Week collection was the start of revolution
          in designer ethics. While donated kits are just the tip of the
          iceberg, this collection is set to break the prejudices towards
          sustainable runway fashion.{" "}
        </p>
        {bigImages.map(image => {
          return (
            <Img
              key={image.node.childImageSharp.fluid.src}
              fluid={image.node.childImageSharp.fluid}
              style={{ marginTop: "1em" }}
            ></Img>
          )
        })}
        {/* <Img fluid={bigImages[0].node.childImageSharp.fluid}></Img> */}
        <ImageGrid>
          {gridImages.map(image => (
            <Img
              key={image.node.childImageSharp.fluid.src}
              fluid={image.node.childImageSharp.fluid}
              className="image-child"
            />
          ))}
        </ImageGrid>
      </Wrapper>
    </Layout>
  )
}

export default FashionWeek
